import Carousel from "react-multi-carousel";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { CarouselResponsive } from "../uti/uti";

import Banner1 from './../assets-dream/img/aboutus/banner-01.jpg'
import Banner2 from './../assets-dream/img/aboutus/banner-02.jpg'
import Banner3 from './../assets-dream/img/aboutus/banner-03.jpg'

import Team1 from './../assets-dream/img/aboutus/team-05.jpg'
import Ground1 from './../assets/images/ground1.png'
import Ground2 from './../assets/images/ground2.png'
import Ground3 from './../assets/images/ground3.png'


export default function Support() {


    return (
        <>
            <Header />

            <div className="container"  style={{ marginTop: 150, marginBottom:100 }}  >
                <div className="row">
                    <div className="col-md-6">
                    <h1>
                        Let's get you
                        <br /> some help!
                    </h1>
                    <h6>
                        Have any issue? Send us an <span className="email-us">email.</span>
                    </h6>
                    <div className="img-wrapper">
                        <img
                        src="https://i.ibb.co/bWfN3Qy/undraw-onboarding-o8mv-1.png"
                        alt="undraw-onboarding-o8mv-1"
                        border={0}
                        />
                    </div>
                    </div>
                    <div className="col-md-6">
                        <h3 style={{lineHeight:"40px"}} >If you need help or have any questions about The Sports Arena, feel free to reach out to us at  <a href="mailto:thesportsarena.xyz@gmail.com" >thesportsarena.xyz@gmail.com</a> We're here to assist you!</h3>
                    <form style={{display:'none'}} >
                        <div className="form-group">
                        <label htmlFor="list">What list are you looking for ?</label>
                        <input
                            type="text"
                            className="form-control"
                            id="list"
                            aria-describedby="emailHelp"
                        />
                        </div>
                        <div className="form-group">
                        <label htmlFor="tags">Please Few Primary Tags ?</label>
                        <input type="text" className="form-control" id="tags" />
                        </div>
                        <div className="form-group">
                        <label htmlFor="describe">Please desribe your list needs ?</label>
                        <textarea
                            type="text"
                            className="form-control"
                            id="describe"
                            rows={5}
                            defaultValue={""}
                        />
                        </div>
                        <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Select Category</label>
                        <select className="form-control" id="exampleFormControlSelect1">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                        </div>
                        <button type="button" className="btn btn-primary">
                        <span>Submit</span> <i className="fas fa-long-arrow-alt-right" />
                        </button>
                    </form>
                    </div>
                </div>
                </div>



            <Footer />
        </>
    )
}